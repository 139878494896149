@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.dashboard-sidebar1 {
  height: 100vh;
  // padding: 30px 2vw 0px 2vw;
  overflow-y: scroll;
  width: 20vw;
  background: #ededed5e;
  position: relative;

  .logo-border {
    width: 100%;
    height: 70px;
    padding: 0 2vw 0 2vw;
    border-bottom: 0.5px solid #e5e5e5;
    display: flex;
    align-items: center;
    padding-left: 2vw;

    .dashboard-left {
      width: 100%;
    }
  }


  .each-sidenav {
    border-radius: 5px;
    height: 43px;
    width: calc(90%);
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: #100f0d;
    cursor: pointer;
    margin: 15px 0;
    padding-left: 25px;
    &:hover {
      transform: scale(1.05);
      transition: all ease 0.2s;
      font-weight: 600;
    }
  }

  .sidebar-line {
    padding-right: 2vw;
    margin: 30px 0px;
    // background: #d9d9d9;
    height: 1px;
    width: 100%;
    margin-left: -20px;
  }
}

.dashboard-logo {
  width: 14vw;
}

.side-btn {
  cursor: pointer;
  @include btn-animate(#fff);
}
