.mapspage {
  width: 100%;
  height: 100%;

  .maps-navbar {
    width: 100%;
    height: 4rem;
    padding: 0 8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .hamMenu {
      display: none;
      position: absolute;
      left: 1rem;
      height: 100%;

      img {
        width: 20px;
      }
    }

    .logo {
      display: flex;
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.05);
      }
    }

    .menu-items {
      display: flex;
      gap: 2rem;
      align-items: center;
      font-size: 0.9rem;

      p {
        margin: 0;
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .btns-div {
      display: flex;
      gap: 1rem;

      .gs-Btn {
        color: white;
        font-weight: 500;
        border-radius: 35px;
        background: linear-gradient(89deg, #47b4d5 0%, #29449d 100%);
        padding: 0.7rem 1.5rem;
        font-size: 0.8rem;
      }

      .business-btn {
        color: white;
        font-weight: 500;
        border-radius: 35px;
        background: #100f0d;
        padding: 0.5rem 1.5rem;
      }

      div {
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }

  .maps-color-box {
    width: 100%;
    height: 1rem;
    background: linear-gradient(89deg, #47b4d5 0%, #29449d 100%);
  }

  .maps-container {
    width: 100%;
    height: calc(100% - 5rem);
    display: flex;

    .maps-sidebar {
      width: 25%;
      height: 100%;
      border-right: 1px solid #e5e5e5;
      overflow-y: scroll;

      .top-icons {
        display: flex;
        padding: 1rem;
        border-bottom: 1px solid #e5e5e5;
        gap: 2rem;

        .each-icon {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          align-items: center;
          cursor: pointer;
          transition: all ease 0.2s;

          .border-div {
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;

            img {
              width: 60%;
              height: 60%;
            }
          }

          .icon-name-txt {
            font-size: 0.7rem;
            text-align: center;
          }

          &:hover {
            .border-div {
              box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            }

            .icon-name-txt {
              transform: scale(1.05);
            }
          }
        }
      }

      .mid-area {
        width: 100%;
        padding: 2rem 1rem;
        border-bottom: 1px solid #e5e5e5;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .input-div1 {
          width: 100%;
          border: 1px solid #e5e5e5;
          border-radius: 45px;

          input {
            width: 100%;
            border: none;
            padding: 1rem;
            border-radius: 45px;

            &::placeholder {
              opacity: 0.5;
            }
          }
        }

        .destination-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .dest-txt {
            font-size: 0.9rem;
            font-weight: 500;
            display: flex;
            justify-content: space-between;
            align-items: center;

            img {
              cursor: pointer;
              transition: all ease 0.2s;

              &:hover {
                transform: scale(1.05);
              }
            }
          }

          .input-div2 {
            width: 100%;
            border: 1px solid #e5e5e5;
            border-radius: 45px;

            input {
              width: 100%;
              border: none;
              padding: 1rem;
              border-radius: 45px;

              &::placeholder {
                opacity: 0.5;
              }
            }

            .react-datepicker-wrapper {
              width: 100%;
            }
          }
        }

        .add-div {
          width: fit-content;
          font-size: 0.8rem;
          display: flex;
          align-items: center;
          gap: 7px;
          cursor: pointer;
          transition: all ease 0.2s;

          &:hover {
            transform: scale(1.05);
          }
        }

        .maps-btns-div {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .gs-Btn-maps {
            color: white;
            font-weight: 500;
            border-radius: 35px;
            text-align: center;
            background: linear-gradient(89deg, #47b4d5 0%, #29449d 100%);
            padding: 0.9rem 1.5rem;
            font-size: 0.8rem;
            cursor: pointer;
            transition: all ease 0.2s;

            &:hover {
              transform: scale(1.05);
            }
          }

          .reset-btn {
            color: #100f0d;
            border-radius: 35px;
            text-align: center;
            font-size: 0.8rem;
            padding: 0.9rem 1.5rem;
            font-weight: 500;
            border: 1px solid #e5e5e5;
            cursor: pointer;
            transition: all ease 0.2s;

            &:hover {
              transform: scale(1.05);
            }
          }
        }
      }
    }

    .maps-content-area {
      width: 75%;
      height: 100%;
    }
  }
}

@media screen and (max-width: 800px) {
  .mapspage {
    .maps-navbar {
      .hamMenu {
        display: flex;
      }
      .menu-items {
        display: none;
      }
      .btns-div {
        display: none;
      }
    }

    .maps-color-box {
      height: 0.5rem;
    }

    .maps-container {
      height: calc(100% - 4.5rem);
      .maps-sidebar {
        width: 100%;
        .mid-area {
          .input-div1 {
            width: 80%;
          }
          .destination-container {
            .input-div2 {
              width: 80%;
            }
          }
        }
      }
    }
  }
}
