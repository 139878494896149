@import "../../static/scss/colors";
@import "../../static/scss/mixin";


.dashboard-body {
  height: calc(100vh);
  width: 100vw;
  display: flex;
  text-transform: capitalize !important;
}

.dashboard-screens {
  width: 80vw;

  .row-1 {
    display: flex;
    height: calc(55% - 0.5px);
    border-bottom: 0.5px solid #E5E5E5;
  }

  .box-1 {
    width: 37vw;
    height: 100%;
  }

  .box-2 {
    border-left: 0.5px solid #E5E5E5;
    width: calc(43vw - 0.5px);
    height: 100%;
  }

  .row-2 {
    display: flex;
    height: calc(45% - 0.5px);
    border-bottom: 0.5px solid #E5E5E5;
  }

  .box-3 {
    width: 25vw;
    height: 100%;
  }

  .box-4 {
    border-left: 0.5px solid #E5E5E5;
    width: calc(55vw - 0.5px);
    height: 100%;
  }
}

.dash-nav {
  display: flex;
  justify-content: space-between;
  background: #FFFFFF;
  border-bottom: 0.5px solid #E5E5E5;
  width: 80vw;
  height: 70px;
}

.search-input-box {
  width: calc(80vw - 171px);
  height: 70px;
  padding: 5px 35px;
  border-right: 0.5px solid #E5E5E5;
}

.search-input {
  outline: none;
  border: none;
  height: 60px;
  width: calc(72vw - 171px);
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1F304F;
}

.search-box {
  border-right: 0.5px solid #E5E5E5;
  width: 70px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.search-icon {
  height: 25px;
  width: 25px;
}

.full-user {
  width: 110px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease .2s;
  cursor: pointer;
  &:hover{
    transform: scale(5);
  }
}

.arrow-box {
  margin-left: 14px;
}

.user-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.account-container {
  display: flex;
  width: 80vw;
  height: calc(100vh - 70px);
  background-color: #FFFFFF;
  overflow: hidden;
}

.account-left {
  width: calc(60vw);
  height: calc(100vh - 70px);
  background-color: #FFFFFF;
  padding: 0px 40px 40px 30px;
}

.account-right {
  border-left: 0.5px solid #E5E5E5;
  width: 20vw;
  height: calc(100vh - 70px);
  background-color: #FFFFFF;
}

.filter-actions-box {
  display: flex;
  margin: 25px 0px;
}

.filter-action1,
.filter-action2 {
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  margin-right: 25px;
  padding: 16px 45px;
  font-weight: 500;
  font-size: 15px;
  color: #1F304F;
  cursor: pointer;
  @include btn-animate(#1F304F);
}

.all-account {
  height: 87%;
  overflow-y: scroll;
  width: calc(60vw - 70px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 40px;
}

.each-account {
  width: 48%;
  padding: 35px;
  border: 1px solid #E5E5E5;
  border-radius: 15px;
  margin-bottom: 30px;
}

.account-img-box {
  display: flex;
  justify-content: center;
  height: 108px;
}

.account-img {
  height: 108px;
  width: 108px;
  border-radius: 50%;
}

.account-name {
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  margin-top: 20px;
  color: #1F304F;
  display: flex;
  justify-content: center;
}

.account-work {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  padding-top: 7px;
  color: #1F304F;
  display: flex;
  justify-content: center;
  text-align: center;
}

.account-work-title {
  font-weight: 600;
  font-size: 19px;
  line-height: 23px;
  color: #1F304F;
  transition: all ease .2s;

  &:hover {
    transform: scale(1.1);
  }
}

.account-country {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #1F304F;
  margin-top: 35px;
  margin-bottom: 15px;
}

.account-countries-all {
  width: 90%;
  display: flex;
  margin-bottom: 25px;
}

.account-countries-each {
  width: 32%;
  padding: 15px 0px;
  background: rgba(255, 255, 255, 0.25);
  border: 0.5px solid #E5E5E5;
  border-radius: 10px;
  cursor: pointer;
  @include btn-animate(#1F304F);
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  color: #1F304F;
}

.account-countries-each1 {
  width: 32%;
  margin-left: 20px;
  padding: 15px 0px;
  background: rgba(255, 255, 255, 0.25);
  border: 0.5px solid #E5E5E5;
  border-radius: 10px;
  cursor: pointer;
  @include btn-animate(#1F304F);
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  color: #1F304F;
}

.account-countries-more {
  width: 32%;
  margin-left: 20px;
  padding: 15px 0px;
  background: rgba(31, 48, 79, 0.1);
  border-radius: 10px;
  cursor: pointer;
  @include btn-animate(#1F304F);
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  color: #1F304F;
}

.account-speaclities {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #1F304F;
  margin-top: 35px;
  margin-bottom: 15px;
}

.account-speaclities-all {
  width: 100%;
  display: flex;
  margin-bottom: 25px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.account-speaclities-each {
  margin-bottom: 20px;
  width: 60%;
  padding: 15px 0px;
  background: rgba(255, 255, 255, 0.25);
  border: 0.5px solid #E5E5E5;
  border-radius: 10px;
  cursor: pointer;
  @include btn-animate(#1F304F);
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  color: #1F304F;
}

.account-speaclities-each1 {
  margin-bottom: 20px;
  width: 35%;
  padding: 15px 0px;
  background: rgba(255, 255, 255, 0.25);
  border: 0.5px solid #E5E5E5;
  border-radius: 10px;
  cursor: pointer;
  @include btn-animate(#1F304F);
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  color: #1F304F;
}

.account-speaclities-each2 {
  margin-bottom: 20px;
  width: 35%;
  padding: 15px 0px;
  background: rgba(255, 255, 255, 0.25);
  border: 0.5px solid #E5E5E5;
  border-radius: 10px;
  cursor: pointer;
  @include btn-animate(#1F304F);
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  color: #1F304F;
}

.account-speaclities-each3 {
  margin-bottom: 20px;
  width: 60%;
  padding: 15px 0px;
  background: rgba(255, 255, 255, 0.25);
  border: 0.5px solid #E5E5E5;
  border-radius: 10px;
  cursor: pointer;
  @include btn-animate(#1F304F);
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  color: #1F304F;
}

.account-see-more {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px;
  border-radius: 10px;
  background: #59A2DD;
  border: 0.5px solid #59A2DD;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  cursor: pointer;
  @include btn-animate(#FFFFFF);
}

.account-right-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1F304F;
  margin-bottom: 20px;
}

.account-right-box1 {
  height: 50%;
  padding: 20px 25px;
  border-bottom: 0.5px solid #E5E5E5;

}

.account-right-box2 {
  height: 50%;
  padding: 20px 25px;
}



.check-div {
  margin: 10px 0px;
  display: flex;
  align-items: center;
}


.check-input {
  height: 25px;
  width: 25px;
  accent-color: #1F304F;
}

.check-label {
  margin-left: 18px;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  color: #1F304F;
}

.account-right-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px;
  border-radius: 35px;
  background: #FFFFFF;
  border: 0.5px solid #E5E5E5;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #1F304F;
  cursor: pointer;
  @include btn-animate(#1F304F);
}

.service-container {
  // width: 80vw;
  // height: calc(100vh - 70px);
  // background-color: #FFFFFF;
  // padding: 20px 0px 0px 20px;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-evenly;
  display: flex;
  overflow-x: hidden;
}

.service-main{
  width: 80vw;
  overflow-x: scroll;
  height: calc(100vh - 70px);
  background-color: #FFFFFF;
  padding: 40px 0px 20px 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.serviceby-allbox {
  display: -webkit-inline-box;
  overflow-x: scroll;
  height: 31vh;
  // width: 100%;
}

.check {
  border: 0.5px solid #E5E5E5;
  border-radius: 5px;
  padding: 6px 6px 2px 6px;
}

.pagemask {
  position: fixed !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden !important;
}

.endbox {
  background-color: #FFFFFF;
  border-radius: 25px;
  padding: 80px;
  width: fit-content;
  text-align: center;

}

.full-box {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.follow-text {
  font-weight: 600;
  font-size: 23px;
  line-height: 45px;
  margin-top: 30px;
  color: #1F304F;
}


///click arrow

.zoom1 {
  background: #f3f4f5;
  border: 0;
  border-radius: 30px;
  box-shadow: 0 10px 10px rgb(0 0 0 / 10%);
  cursor: pointer;
  display: inline-block;
  height: 40px;
  overflow: hidden;
  transition: all .3s ease-out;
  width: 40px;
}

.check1-text {
  display: none;
}

.clickarrow-img {
  height: 20px;
  width: 20px;
}


.each-service {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  padding: 20px;
  margin-right: 35px;
  width: 400px;
  height: 31vh;

  &:hover {
    background-color: #F5F5F5;

    .check1-text {
      color: #182542;
      display: inline-flex;
      margin-left: 9px;
      margin-top: 5px;
      font-size: 15px;
      font-weight: 700;
    }

    .clickarrow-img {
      margin-left: 0.2rem;
      margin-top: 0.3rem;
      width: 20px;
    }

    .zoom1 {
      background: #fffbf0;
      height: 40px;
      text-align: center;
      width: 120px;
    }
  }
}

.serviceby-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  width: fit-content;
}

.serviceby-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #1F304F;
  margin-right: 30px;

}

.serciceby-option-box {
  background: #FFFFFF;
  border: 0.5px solid #E5E5E5;
  border-radius: 35px;
  padding: 10px 22px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}

.serviceby-img {
  height: 27px;
  width: 27px;
  margin-right: 8px;
  border-radius: 50%;
}

.serviceby-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #1F304F;
  margin-right: 20px;
}

.each-service-title {
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #1F304F;
  margin-top: 14px;
}

.each-service-desc {
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  /* or 167% */

  display: flex;
  align-items: center;

  color: #1F304F;
  margin: 14px 0px;

}

.each-service-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.each-service-price {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;

  color: #1F304F;
  margin-right: 25px;

}

.serviceby-title1 {
  margin: 14px 0px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #1F304F;
}

.each-service-img{
  height: 35px;
  width: 35px;
}

.all-follow{
  padding: 20px 35px;
  background: #FFFFFF;
  border: 0.5px solid #EDEDED;
  position: absolute;
  top:70px;
  right: 0%;
  // margin-top:calc(-100vh + 70px);
  // margin-left: clac(100vw - 460px);
  z-index: 22;
  width: 460px;
  height: calc(100vh - 70px);
  // overflow-y: scroll;
  -webkit-animation: righttoleft .15s ease-in forwards;
    animation: righttoleft .15s ease-in forwards;
}

@keyframes righttoleft {
  0% {
    transform: translateX(100px);
  }
  25% {
    transform: translateX(75px);
  }
  50% {
    transform: translateX(50px);
  }
  75% {
    transform: translateX(25px);
  }
  100% {
    transform: translateX(0px);
  }
}

.follow-icon{
  width: 47px;
  height: 47px;
  border-radius: 50%;
}

.m-drop{
  padding: 20px 35px;
  background: #FFFFFF;
  border: 0.5px solid #EDEDED;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  position: absolute;
  top:78px;
  right: 40px;
  z-index: 22;
  width: 350px;
}

.m-each{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  font-weight: 500;
  font-size: 15px;
  color: #1F304F;
  cursor: pointer;
  &:hover{
    transform: scale(1.1);
    transition: all ease .2s;
  }
}

.m-left{
  display: flex;
}

.m-left-text{
  margin-left: 7px;
}

.all-follow-head-box{
  display: flex;
  justify-content: space-between;
  align-items: center; 
  font-weight: 500;
  font-size: 22px;
  color: #1F304F;
}

.follow-current{
  margin: 35px 0px;
}

.follow-current-head{
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 20px;
  color: #1F304F;
}

.scrollable-follow{
  height: calc(100vh - 150px);
  overflow-y: scroll;
}

.each-follow{
  width: 100%;
  padding: 20px;
  align-items: center;
  background: #FFFFFF;
  border: 0.5px solid #E5E5E5;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all ease .3s;
  &:hover{
    border: 0.5px solid #1F304F;
  }
}

.follow-details{
  display: flex;
}

.follow-name-box{
  margin-left: 20px;
}

.follow-pop-name{
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  color: #1F304F;
}

.follow-pop-time{
  font-weight: 400;
font-size: 12px;
line-height: 15px;
color: #1F304F;
}

.follow-button-box{
  margin-top: 20px;
  display: flex;
}

.unfollow-btn{
  width: fit-content;
  padding: 10px 30px;
  background: #FE2C55;
border-radius: 35px;
margin-right: 15px;
font-weight: 600;
font-size: 12px;
line-height: 15px;
color: #FFFFFF;
cursor: pointer;
  @include btn-animate(#FFFFFF);
}

.profilebtn{
  width: fit-content;
  padding: 10px 30px;
  background: #1F304F;
  border-radius: 35px;
margin-right: 15px;
font-weight: 600;
font-size: 12px;
line-height: 15px;
color: #FFFFFF;
cursor: pointer;
  @include btn-animate(#FFFFFF);
}