.landingPage {
    padding: 4rem;
    border-radius: 15px;
    max-width: 1300px;
    margin: 0 auto;
    background-image: url("../../images/landing.svg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    margin-top: 14rem;
    margin-bottom: 4rem;

    .text {
        cursor: pointer;
        position: relative;
        z-index: 2;
        font-size: 16px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        color: #100F0D;

        height:
            50px;
        padding: 0 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        transition: all 0.5s ease;

        &:hover {
            transform: scale(1.04);
            transition: all 0.5s ease;
        }
    }

    .headers {
        font-size: 40px;
        font-weight: 900;
        line-height: 55px;
        text-align: left;
        color: #FFFFFF;
        width: 413px;
        position: relative;
        z-index: 2;
        margin-top: 40px;
        margin-bottom: 30px;
    }

    .blackling {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        top: 0;
        border-radius: 15px;
        left: 0;
        background: linear-gradient(90.99deg, rgba(0, 0, 0, 0.5) 46.04%, rgba(255, 255, 255, 0) 99.19%);

    }

    .lablelist {
        display: flex;
        align-items: center;
        padding: 0.5rem;
        gap: 1rem;
        position: relative;
        z-index: 2;
        background: #FFFFFF;
        border-radius: 10px;
        margin-top: 9rem;
    }


    .para {
        position: relative;
        z-index: 2;
        margin-top: 1rem;
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        text-align: left;
        color: #FFFFFF;
        width: 392px
    }
}


body {
    overflow: scroll;
}

.secondSecion {
    padding-bottom: 4rem;

    .text {
        color: #100F0D;

        font-size: 35px;
        font-weight: 300;
        line-height: 50px;
        text-align: center;

    }

    span {
        font-weight: 600;
    }

    .liner {
        width: 100%;
        height: 1px;
        background: #E5E5E5;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .flist {
        color: #100F0D;
        font-size: 12px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;


    }

    .innerlist {
        border: 1px solid #E5E5E5;
        width: 32%;

        padding: 2rem;
        border-radius: 15px;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
            transition: all 0.5s ease;
        }
    }

    .inner {
        max-width: 1300px;
        width: 100%;
        margin: auto;
    }

    .learn {
        margin-top: 1rem;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        color: #FFFFFF;
        background: #100F0D;
        border-radius: 10px;
        transition: all 0.5s ease;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
            transition: all 0.5s ease;
        }
    }

    .carditem {
        display: flex;
        align-items: self-start;
        flex-wrap: wrap;
        margin-top: 3rem;
        gap: 2%;
        row-gap: 1.5rem;

    }

    .Features {
        margin-bottom: 0.7rem;

        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #100F0D;
        text-decoration: underline;
    }

    .listHeader {
        margin-bottom: 11px;
        font-size: 23px;
        font-weight: 600;
        line-height: 25px;
        text-align: left;
        color: #100F0D;
    }

    .para {

        font-size: 13px;
        font-weight: 300;
        line-height: 24px;
        text-align: left;
        color: #100F0D;
    }
}