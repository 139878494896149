.navabar {
    background: white;
    position: fixed;
    z-index: 999;
    width: 100%;
    transition: all 0.5s ease;
    background: transparent;

    .fullwidht {
        border-bottom: 1px solid #ddd;
        width: 100%;
        background: transparent;
    }

    .top {
        max-width: 1300px;
        margin: auto;
        padding: 1.2rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.5s ease;
        background-color: white;
    }

    .bottom {
        max-width: 1300px;
        margin: auto;
        padding: 1.2rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.5s ease;
    }

    .inner {}

    .arraylist {
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 0.5rem;
        transition: all 0.5s ease;

        &:hover {
            transform: scale(1.03);
            transition: all 0.5s ease;
        }
    }

    .item {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #100F0D;
    }

    .right {
        display: flex;
        align-items: center;
        gap: 2REM;
    }

    .text {

        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        color: #100F0D;
    }

    .label {
        cursor: pointer;

        font-size: 12px;
        font-weight: 600;
        line-height: 25px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 1.3rem;
        cursor: pointer;
        border: 1px solid #100F0D;

        height: 39px;

        border-radius: 5px;
        transition: all 0.5s ease;

        &:hover {
            transform: scale(1.03);
            transition: all 0.5s ease;
        }
    }


    .list {

        font-size: 14px;
        font-weight: 400;
        line-height: 25px;
        text-align: center;
        color: #100F0D;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;
        transition: all 0.5s ease;

        &:hover {
            transform: scale(1.03);
            transition: all 0.5s ease;
        }
    }
}